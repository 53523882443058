export const LIST_COUNTRIES = 'LIST_COUNTRIES';
export function listCountries() {
  return {
    type: LIST_COUNTRIES,
  };
}

export const LIST_COUNTRIES_REQUEST = 'LIST_COUNTRIES_REQUEST';
export function listCountriesRequest() {
  return {
    type: LIST_COUNTRIES_REQUEST,
  };
}

export const LIST_COUNTRIES_SUCCESS = 'LIST_COUNTRIES_SUCCESS';
export function listCountriesSuccess(data) {
  return {
    type: LIST_COUNTRIES_SUCCESS,
    data,
  };
}

export const LIST_COUNTRIES_FAILURE = 'LIST_COUNTRIES_FAILURE';
export function listCountriesFailure(error) {
  return {
    type: LIST_COUNTRIES_FAILURE,
    error,
  };
}

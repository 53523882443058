import { EVENT_SETTING_GROUPS } from './eventSettings';

const _ = require('lodash');
const diff = require('deep-object-diff').diff;
const { publicRuntimeConfig = {} } = require('next/config').default();

const isProd = publicRuntimeConfig.DOMAIN === 'prod';

// Return the final setting keys to update in the background,
// and those to request updating in the active view.
const _getDiffResult = (source, target, viewKeys, exclude) => {
  const fullDiffData = _.omit(diff(source, target), exclude);
  const viewDiffData = _.pick(fullDiffData, viewKeys);
  const backgroundDiffData = diff(viewDiffData, fullDiffData);

  const result = {
    updateView: !_.isEmpty(viewDiffData) && viewDiffData,
    updateInBackground: !_.isEmpty(backgroundDiffData) && backgroundDiffData,
  };

  // Prevent overwriting arrays with unincluded values
  _.keys(result).forEach((key) => {
    [
      'surveyQuestions',
      'captureConfig.enabledFilters',
      'captureConfig.overlays',
      'captureConfig.greenScreens',
      'faceEffects.beautification',
      'faceEffects.masks',
      'captureConfig.poseTipsConfiguration.selection',
      'stickers',
    ].forEach((field) => {
      const path = `${key}.${field}`;
      const currDiff = _.get(result, path);
      if (!_.isUndefined(currDiff)) {
        _.set(result, path, _.get(target, field));
      }
    });
  });

  return result;
};

// During a webapp event SSE update request, determine which settings
// are located on the current active tab. For these settings the user
// will be asked whether they want to continue editing or refresh.
// Settings not located in the active view will be overwritten silently.
export const checkEventSseUpdate = (source, target, settingsView) => {
  const { subtab } = settingsView;
  const settingsInView = EVENT_SETTING_GROUPS[subtab] ? EVENT_SETTING_GROUPS[subtab].settings : [];
  return _getDiffResult(source, target, settingsInView, ['dateUpdated']);
};

// During an account settings SSE update request, determine which
// settings to update
export const checkProfileSseUpdate = (source, target, settingsView) => {
  const view = settingsView.tab;
  const viewKeys = {
    information: ['firstName', 'lastName', 'email', 'newEmail', 'phoneNumber'],
    security: ['passcode'],
  };
  return _getDiffResult(source, target, viewKeys[view], ['dateUpdated']);
};

export const eventMerge = (source, target) => {
  return _.mergeWith({}, source, target, (objValue, srcValue) => {
    if (_.isArray(objValue) && _.isArray(srcValue)) {
      return srcValue;
    }
  });
};

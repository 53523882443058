export const loadFonts = async (fonts = []) => {
  return new Promise(async (resolve, reject) => {
    if (!fonts.length || !document.fonts) {
      resolve(false);
    }
    const style = document.createElement('style');
    document.head.appendChild(style);
    const { sheet } = style;
    Promise.all(
      fonts.map(async (font) => {
        return new Promise(async (resolve) => {
          try {
            const fontFace = new FontFace(font.name, `url(${font.url})`);
            const fontData = await fontFace.load();
            document.fonts.add(fontData);
            // console.log('loaded '+font.name)
            resolve(fontData);
          } catch (error) {
            resolve(false);
          }
        });
      })
    ).then((data) => {
      // const fontsLoaded = data.filter(f => f);
      document.fonts.ready.then(() => resolve(true)).catch(() => resolve(false));
    });
  });
};

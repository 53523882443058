import _ from 'lodash';
import {
  GET_PROPOSAL_OVERLAYS,
  GET_PROPOSAL_OVERLAYS_SUCCESS,
  GET_PROPOSAL_OVERLAYS_FAILURE,
} from '../actions/proposalOverlays';

const defaultState = {
  loading: false,
  error: null,
  data: {},
};

export function proposalOverlays(state = defaultState, action) {
  switch (action.type) {
    case GET_PROPOSAL_OVERLAYS:
      return { ...defaultState, loading: true };
    case GET_PROPOSAL_OVERLAYS_SUCCESS:
      return { ...defaultState, loading: false, data: _.keyBy(action.data, 'id') };
    case GET_PROPOSAL_OVERLAYS_FAILURE:
      return { ...defaultState, loading: false, error: action.error };
    default:
      return state;
  }
}

/**
 * Fetch Event data without accessToken
 */
export const GET_PROPOSAL_DATA = 'GET_PROPOSAL_DATA';
export function getProposalData(eventId, isSalsa, forceBranding = false) {
  return {
    type: GET_PROPOSAL_DATA,
    eventId,
    isSalsa,
    forceBranding,
  };
}
export const GET_PROPOSAL_DATA_SUCCESS = 'GET_PROPOSAL_DATA_SUCCESS';
export function getProposalDataSuccess(data) {
  return {
    type: GET_PROPOSAL_DATA_SUCCESS,
    data,
  };
}
export const GET_PROPOSAL_DATA_FAILURE = 'GET_PROPOSAL_DATA_FAILURE';
export function getProposalDataFailure(error) {
  return {
    type: GET_PROPOSAL_DATA_FAILURE,
    error,
  };
}

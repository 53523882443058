const { successURL } = require('../constants').get();

class BackendSuccess {
  constructor() {
    this.URL = successURL;
  }

  login({ email, password }) {
    return this._post(`${this.URL}/user/login`, { email, password });
  }

  getLoginToken({ email, token }) {
    return this._post(`${this.URL}/operator/loginToken`, { email }, { 'x-access-token': token });
  }

  getOperatorToken({ loginToken }) {
    return this._post(`${this.URL}/operator/accessToken`, { loginToken });
  }

  getOperator({ email, accessToken }) {
    return this._post(`${this.URL}/operator`, { email }, { 'x-access-token': accessToken });
  }

  updateOperator({ operatorId, body = {}, accessToken }) {
    return this._post(`${this.URL}/operator/${operatorId}`, body, { 'x-access-token': accessToken });
  }

  _post(url, body = {}, headers = {}) {
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    });
  }
}

export default BackendSuccess;

const uniqid = require('uniqid');
const { publicRuntimeConfig } = require('next/config').default();

const _BASE = {
  keys: {
    STRIPE_PUBLIC_TEST: 'pk_test_HL2BsZEv2pgVgqGeUK4ez5MX',
    STRIPE_PUBLIC_LIVE: 'pk_live_4IHJDpwl357gTixqe924QMBB',
  },
  sseSource: `webapp-sse-${uniqid()}`,
  staticS3Url: 'https://pbsco-static.s3.us-west-2.amazonaws.com',
};

module.exports = {
  getForDomain(domain) {
    const CONSTANTS = {
      local: {
        ..._BASE,
        // vbUseWebRTC: true,
        // consoleLoger: true,
        webAppURL: 'http://localhost:5000',
        analyticsTrackingID: 'G-9FQP2R3FZT',
        billingURL: 'http://localhost:8005',
        quesoURL: 'http://localhost:14000',
        salsaURL: 'http://localhost:3010/gql',
        fiestaURL: 'http://localhost:3010/gql',
        sseURL: 'http://localhost:3010/event-stream',
        liveGalleryURL: 'http://localhost:5000',
        deepARKey: 'ed21983001285631ca8abfbb10699ad13c2fc780b43ec12ed29e644740dfe5a887350bd67719ea91',
        deepARKeyVB: 'f9d0353621e30c1d2ce6c5ed8b8f60dc7b7f5f8d13ca0fdea808c598f7bf30fcff1f11b4e5cbb3c5',
        webrtcURL: 'http://localhost:8006',
        successURL: 'https://successapi.dev.photoboothsupplyco.com',
        dropboxClientId: 'l0tep9uf21dsyyo',
      },
      dev: {
        ..._BASE,
        // vbUseWebRTC: true,
        consoleLoger: true,
        webAppURL: 'https://app.dev.photoboothsupplyco.com',
        analyticsTrackingID: 'G-9FQP2R3FZT',
        billingURL: 'https://billing.dev.boothpics.com',
        quesoURL: 'https://api.dev.boothpics.com',
        salsaURL: 'https://salsa.dev.boothpics.com/gql',
        fiestaURL: 'https://fiesta.dev.boothpics.com/gql',
        sseURL: 'https://salsa.dev.boothpics.com/event-stream',
        liveGalleryURL: 'https://dev.boothpics.com',
        deepARKey: 'ed21983001285631ca8abfbb10699ad13c2fc780b43ec12ed29e644740dfe5a887350bd67719ea91',
        deepARKeyVB: 'f9d0353621e30c1d2ce6c5ed8b8f60dc7b7f5f8d13ca0fdea808c598f7bf30fcff1f11b4e5cbb3c5',
        webrtcURL: 'https://d2w2cyc13zinvh.cloudfront.net',
        successURL: 'https://successapi.dev.photoboothsupplyco.com',
        dropboxClientId: 'l0tep9uf21dsyyo',
      },
      staging: {
        ..._BASE,
        webAppURL: 'https://app.staging.photoboothsupplyco.com',
        analyticsTrackingID: 'G-9FQP2R3FZT',
        billingURL: 'https://billing.staging.boothpics.com',
        quesoURL: 'https://api.beta.boothpics.com',
        salsaURL: 'https://salsa.staging.boothpics.com/gql',
        fiestaURL: 'https://fiesta.staging.boothpics.com/gql',
        sseURL: 'https://salsa.staging.boothpics.com/event-stream',
        liveGalleryURL: 'https://staging.boothpics.com',
        deepARKey: '4ec6e858551c3282e6d2f5052534d39731d366383a026bff60537a3c2057e26bc5f9e20e1873474e',
        deepARKeyVB: 'a2a4b149e996b9d0b7892aeebfc359d167bddaa19994a3800ad9515a7902a3c17de9af20f96bcf32',
        webrtcURL: 'https://d251uquhzcwslu.cloudfront.net',
        successURL: 'https://successapi.photoboothsupplyco.com',
        dropboxClientId: 'kj7nyfx3cal93sq',
      },
      prod: {
        ..._BASE,
        webAppURL: 'https://app.photoboothsupplyco.com',
        analyticsTrackingID: 'G-CXKC2WNHHG',
        billingURL: 'https://billing.boothpics.com',
        quesoURL: 'https://api.boothpics.com',
        salsaURL: 'https://salsa.boothpics.com/gql',
        fiestaURL: 'https://fiesta.boothpics.com/gql',
        sseURL: 'https://salsa.boothpics.com/event-stream',
        liveGalleryURL: 'https://boothpics.com',
        deepARKey: '2c62b8ac6a5dfddb18db57e21827671b30a551cacb511bf0565fa9f52b5b73538d502537057b9644',
        deepARKeyVB: '38d0b2f6d52140ae8ada29243ff0222dd3b04535e963116573db35c106f4db27c4108f162fa739a0',
        webrtcURL: 'https://d22seflr7nh0n3.cloudfront.net',
        successURL: 'https://successapi.photoboothsupplyco.com',
        dropboxClientId: '40zs2fs5ifol9br',
      },
    };

    if (!CONSTANTS[domain]) {
      throw Error(
        `Cannot retrieve constants for unsupported domain [${domain}].  Available domains are [${Object.keys(
          CONSTANTS
        ).join(',')}]`
      );
    }

    return CONSTANTS[domain];
  },
  get(domain) {
    if (process.env.NODE_ENV === 'test') {
      return this.getForDomain('dev');
    }
    const validatedDomain = domain || publicRuntimeConfig.DOMAIN;
    if (!validatedDomain) {
      throw Error('The domain has not been configured. Set the domain, then call get.');
    }
    return this.getForDomain(validatedDomain);
  },
};

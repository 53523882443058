/**
 * Fetch Event overlays without accessToken
 */
export const GET_THREE_SIXTY_PREVIEW = 'GET_THREE_SIXTY_PREVIEW';
export function getThreeSixtyPreview(data) {
  return {
    type: GET_THREE_SIXTY_PREVIEW,
    data,
  };
}
export const GET_THREE_SIXTY_PREVIEW_SUCCESS = 'GET_THREE_SIXTY_PREVIEW_SUCCESS';
export function getThreeSixtyPreviewSuccess(data) {
  return {
    type: GET_THREE_SIXTY_PREVIEW_SUCCESS,
    data,
  };
}
export const GET_THREE_SIXTY_PREVIEW_FAILURE = 'GET_THREE_SIXTY_PREVIEW_FAILURE';
export function getThreeSixtyPreviewFailure(error) {
  return {
    type: GET_THREE_SIXTY_PREVIEW_FAILURE,
    error,
  };
}

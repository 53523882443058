const { billingURL } = require('../constants').get();

class BackendBilling {
  constructor() {
    this.URL = billingURL;
  }

  getProfile({ organizationId }) {
    return fetch(`${this.URL}/organizations/${organizationId}`);
  }

  updateOrganization({ organizationId, requestBody }) {
    return this._put(`${this.URL}/organizations/${organizationId}`, requestBody);
  }

  getInvoices({ organizationId }) {
    return fetch(`${this.URL}/organizations/${organizationId}/invoices`);
  }

  getPricing(requestBody) {
    return this._post(`${this.URL}/pricing`, requestBody);
  }

  updateEnvironment({ env }) {
    let url;
    if (env) {
      url = require('../constants').get(env).billingURL;
    } else {
      url = require('../constants').get().billingURL;
    }
    this.URL = url;
  }

  _put(url, body) {
    return fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  _post(url, body) {
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

export default BackendBilling;

import _ from 'lodash';
import {
  GET_THREE_SIXTY_PREVIEW,
  GET_THREE_SIXTY_PREVIEW_SUCCESS,
  GET_THREE_SIXTY_PREVIEW_FAILURE,
} from '../actions/threeSixtyPreview';

const defaultState = {
  loading: false,
  error: null,
  data: null,
};

export function threeSixtyPreview(state = defaultState, action) {
  switch (action.type) {
    case GET_THREE_SIXTY_PREVIEW:
      return { ...defaultState, loading: true };
    case GET_THREE_SIXTY_PREVIEW_SUCCESS:
      return { ...defaultState, loading: false, data: action.data };
    case GET_THREE_SIXTY_PREVIEW_FAILURE:
      return { ...defaultState, loading: false, error: action.error };
    default:
      return state;
  }
}

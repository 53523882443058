const crypto = require('crypto');
const uniqid = require('uniqid');

exports.isRecommendedBrowser = (useragent) => {
  const browsers = ['Chrome', 'Safari'];
  let recommended = false;
  browsers.forEach((browser) => {
    if (useragent.family === browser || useragent.family.includes(browser)) {
      recommended = true;
    }
  });
  return recommended;
};

exports.isMobile = () => {
  let check = false;
  ((a) => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

exports.isIOS = () => {
  return (
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream
  );
};

exports.isEmail = (input) => {
  const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return EMAIL_REGEX.test(input);
};

// READ QUERYSTRING TO SHOW BETA FEATURES
exports.getQueryString = (field, url) => {
  return new Promise((resolve, reject) => {
    const href = url || window.location.href;
    const reg = new RegExp(`[?&]${field}=([^&#]*)`, 'i');
    const string = reg.exec(href);
    resolve(string ? string[1] : null);
  });
};

exports.isPortrait = (img) => {
  const realWidth = img.naturalWidth;
  const realHeight = img.naturalHeight;
  return realHeight / realWidth > 1;
};

exports.getApiName = (id = '') => {
  if (id.length === 5) {
    return 'queso';
  }
  if (id.length === 4 || id.length === 6) {
    return 'salsa';
  }
  return null;
};

// Remove invalid characters from an event ID
exports.parseEventId = (id = '') => {
  return id.replace(/[^A-Za-z0-9]/g, '');
};

exports.saveToLocalStorage = (key, value, expires = false) => {
  if (value === null || value === undefined) {
    return;
  }
  if (expires) {
    sessionStorage.setItem(key, JSON.stringify(value));
    return;
  }
  localStorage.setItem(key, JSON.stringify(value));
};

exports.retrieveFromLocalStorage = (key) => {
  const item = localStorage.getItem(key) || sessionStorage.getItem(key);
  return JSON.parse(item);
};

exports.removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
};

const isValidSubdomain = (subdomain) => {
  return (
    !['dev', 'beta', 'staging', 'www', 'boothpics', 'localboothpics'].includes(subdomain) &&
    !subdomain.startsWith('localhost')
  );
};
exports.isValidSubdomain = isValidSubdomain;

exports.fetchFromSalsa = (salsaUrl, query, accessToken, subdomain) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (accessToken) {
    headers['x-access-token'] = accessToken;
  }
  if (isValidSubdomain(subdomain)) {
    headers['X-Subdomain'] = subdomain;
  }

  return fetch(`${salsaUrl}`, {
    method: 'POST',
    body: JSON.stringify({ query }),
    headers,
  });
};

// Determine color shade
const getColorShade = (color, opposite = false) => {
  let shade = color;
  if (!shade) {
    return '#000000';
  }
  shade = shade.slice(0, 7);
  // Variables for red, green, blue values
  let r;
  let g;
  let b;
  // Check the format of the color, HEX or RGB?
  if (shade.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    shade = shade.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    r = shade[1];
    g = shade[2];
    b = shade[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    shade = +`0x${shade.slice(1).replace(shade.length < 5 && /./g, '$&$&')}`;
    r = shade >> 16;
    g = (shade >> 8) & 255;
    b = shade & 255;
  }
  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 150) {
    return opposite ? 'dark' : 'light';
  }
  return opposite ? 'light' : 'dark';
};
exports.getColorShade = getColorShade;

// Return either black or white for a given bg color
exports.getContrastColor = (color) => {
  return getColorShade(color) === 'dark' ? '#ffffff' : '#000000';
};

exports.readInputFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = (evt) => {
      resolve({ data: new Int8Array(evt.target.result), type: file.type });
    };
    reader.onerror = (event) => {
      reader.abort();
      reject(event);
    };
  });
};

exports.md5 = (data) => {
  return crypto.createHash('md5').update(data).digest('hex');
};

exports.isImage = async (url) => {
  return new Promise((resolve) => {
    const timeout = 5000;
    const img = new Image();
    let timedOut = false;
    let timer;
    img.onerror = () => {
      if (!timedOut) {
        clearTimeout(timer);
        resolve(false);
      }
    };
    img.onabort = img.onerror;
    img.onload = () => {
      if (!timedOut) {
        clearTimeout(timer);
        resolve(true);
      }
    };
    img.src = url;
    timer = setTimeout(() => {
      timedOut = true;
      // reset .src to invalid URL so it stops previous
      // loading, but doesn't trigger new load
      img.src = '//!!!!/test.jpg';
      resolve(false);
    }, timeout);
  });
};

exports.preloadImage = async (url) => {
  return new Promise((resolve) => {
    const timeout = 5000;
    const img = new Image();
    let timedOut = false;
    let timer;
    img.onerror = () => {
      if (!timedOut) {
        clearTimeout(timer);
        resolve(false);
      }
    };
    img.onabort = img.onerror;
    img.onload = () => {
      if (!timedOut) {
        clearTimeout(timer);
        resolve(img);
      }
    };
    img.src = url;
    timer = setTimeout(() => {
      timedOut = true;
      // reset .src to invalid URL so it stops previous
      // loading, but doesn't trigger new load
      img.src = '//!!!!/test.jpg';
      resolve(false);
    }, timeout);
  });
};

exports.getVideoImage = (path, secs = 0.1) => {
  return new Promise((resolve) => {
    const me = this;
    const video = document.createElement('video');
    video.setAttribute('crossorigin', 'anonymous');
    video.onloadedmetadata = () => {
      this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration);
    };
    video.onseeked = (e) => {
      const canvas = document.createElement('canvas');
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.src = canvas.toDataURL();
      resolve(img);
    };
    video.onerror = (e) => {
      resolve(null);
    };
    video.src = path;
  });
};

exports.shuffle = (shuffling) => {
  const shuffled = shuffling;
  let currentIndex = shuffled.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = shuffled[currentIndex];
    shuffled[currentIndex] = shuffled[randomIndex];
    shuffled[randomIndex] = temporaryValue;
  }

  return shuffled;
};

exports.isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0;
};

exports.supportsHEVCAlpha = () => {
  const navigator = window.navigator;
  const ua = navigator.userAgent.toLowerCase();
  const hasMediaCapabilities = !!(navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo);
  const isSafari = ua.indexOf('safari') !== -1 && !(ua.indexOf('chrome') !== -1) && ua.indexOf('version/') !== -1;

  return isSafari && hasMediaCapabilities;
};

exports.isIE11 = () => {
  return !!window.navigator.userAgent.match(/Trident\/7\./, []);
};

exports.phoneCountryCodes = [
  {
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'AF',
  },
  {
    name: 'Aland Islands',
    dial_code: '+358',
    code: 'AX',
  },
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL',
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    code: 'DZ',
  },
  {
    name: 'AmericanSamoa',
    dial_code: '+1684',
    code: 'AS',
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD',
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'AO',
  },
  {
    name: 'Anguilla',
    dial_code: '+1264',
    code: 'AI',
  },
  {
    name: 'Antarctica',
    dial_code: '+672',
    code: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'AG',
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR',
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM',
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW',
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT',
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ',
  },
  {
    name: 'Bahamas',
    dial_code: '+1242',
    code: 'BS',
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    code: 'BH',
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD',
  },
  {
    name: 'Barbados',
    dial_code: '+1246',
    code: 'BB',
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY',
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE',
  },
  {
    name: 'Belize',
    dial_code: '+501',
    code: 'BZ',
  },
  {
    name: 'Benin',
    dial_code: '+229',
    code: 'BJ',
  },
  {
    name: 'Bermuda',
    dial_code: '+1441',
    code: 'BM',
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    code: 'BT',
  },
  {
    name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    code: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA',
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    code: 'BW',
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    dial_code: '+673',
    code: 'BN',
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF',
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI',
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    code: 'KH',
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    code: 'CM',
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'CA',
  },
  {
    name: 'Cape Verde',
    dial_code: '+238',
    code: 'CV',
  },
  {
    name: 'Cayman Islands',
    dial_code: '+1345',
    code: 'KY',
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF',
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'TD',
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL',
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN',
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'CC',
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    code: 'KM',
  },
  {
    name: 'Congo',
    dial_code: '+242',
    code: 'CG',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    dial_code: '+243',
    code: 'CD',
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'CK',
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
  },
  {
    name: "Cote d'Ivoire",
    dial_code: '+225',
    code: 'CI',
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR',
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU',
  },
  {
    name: 'Cyprus',
    dial_code: '+357',
    code: 'CY',
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ',
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK',
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    code: 'DJ',
  },
  {
    name: 'Dominica',
    dial_code: '+1767',
    code: 'DM',
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1',
    code: 'DO',
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC',
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG',
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ',
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER',
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE',
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    code: 'FK',
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO',
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ',
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'FI',
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'FR',
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    code: 'GF',
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF',
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    code: 'GA',
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM',
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE',
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'DE',
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH',
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI',
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'GR',
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    code: 'GL',
  },
  {
    name: 'Grenada',
    dial_code: '+1473',
    code: 'GD',
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP',
  },
  {
    name: 'Guam',
    dial_code: '+1671',
    code: 'GU',
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT',
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'GG',
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW',
  },
  {
    name: 'Guyana',
    dial_code: '+592',
    code: 'GY',
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT',
  },
  {
    name: 'Holy See (Vatican City State)',
    dial_code: '+379',
    code: 'VA',
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN',
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU',
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS',
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN',
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
  },
  {
    name: 'Iran',
    dial_code: '+98',
    code: 'IR',
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ',
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE',
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'IM',
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL',
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'IT',
  },
  {
    name: 'Jamaica',
    dial_code: '+876',
    code: 'JM',
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'JP',
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE',
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    code: 'JO',
  },
  {
    name: 'Kazakhstan',
    dial_code: '+7',
    code: 'KZ',
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE',
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI',
  },
  {
    name: 'Kosovo',
    dial_code: '+383',
    code: 'XK',
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG',
  },
  {
    name: 'Laos',
    dial_code: '+856',
    code: 'LA',
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV',
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    code: 'LB',
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    code: 'LS',
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    code: 'LY',
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI',
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT',
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU',
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'MO',
  },
  {
    name: 'Macedonia',
    dial_code: '+389',
    code: 'MK',
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG',
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    code: 'MW',
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY',
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    code: 'MV',
  },
  {
    name: 'Mali',
    dial_code: '+223',
    code: 'ML',
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT',
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH',
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    code: 'MQ',
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR',
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    code: 'MU',
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT',
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dial_code: '+691',
    code: 'FM',
  },
  {
    name: 'Moldova',
    dial_code: '+373',
    code: 'MD',
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC',
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN',
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME',
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'MS',
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA',
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ',
  },
  {
    name: 'Myanmar',
    dial_code: '+95',
    code: 'MM',
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA',
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR',
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP',
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL',
  },
  {
    name: 'Netherlands Antilles',
    dial_code: '+599',
    code: 'AN',
  },
  {
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'NC',
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ',
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI',
  },
  {
    name: 'Niger',
    dial_code: '+227',
    code: 'NE',
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'NU',
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'NF',
  },
  {
    name: 'North Korea',
    dial_code: '+850',
    code: 'KP',
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1670',
    code: 'MP',
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'NO',
  },
  {
    name: 'Oman',
    dial_code: '+968',
    code: 'OM',
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    code: 'PK',
  },
  {
    name: 'Palau',
    dial_code: '+680',
    code: 'PW',
  },
  {
    name: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    code: 'PS',
  },
  {
    name: 'Panama',
    dial_code: '+507',
    code: 'PA',
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG',
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY',
  },
  {
    name: 'Peru',
    dial_code: '+51',
    code: 'PE',
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    code: 'PH',
  },
  {
    name: 'Pitcairn',
    dial_code: '+64',
    code: 'PN',
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'PL',
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT',
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1',
    code: 'PR',
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA',
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'RO',
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'RU',
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    code: 'RW',
  },
  {
    name: 'Reunion',
    dial_code: '+262',
    code: 'RE',
  },
  {
    name: 'Saint Barthelemy',
    dial_code: '+590',
    code: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    code: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    code: 'KN',
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1758',
    code: 'LC',
  },
  {
    name: 'Saint Martin',
    dial_code: '+590',
    code: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    code: 'VC',
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS',
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    code: 'ST',
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN',
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS',
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC',
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL',
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG',
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK',
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI',
  },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB',
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO',
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS',
  },
  {
    name: 'South Korea',
    dial_code: '+82',
    code: 'KR',
  },
  {
    name: 'South Sudan',
    dial_code: '+211',
    code: 'SS',
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'ES',
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK',
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    code: 'SD',
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    code: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    code: 'SJ',
  },
  {
    name: 'Swaziland',
    dial_code: '+268',
    code: 'SZ',
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE',
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    dial_code: '+963',
    code: 'SY',
  },
  {
    name: 'Taiwan',
    dial_code: '+886',
    code: 'TW',
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dial_code: '+255',
    code: 'TZ',
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH',
  },
  {
    name: 'Timor-Leste',
    dial_code: '+670',
    code: 'TL',
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'TG',
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK',
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1868',
    code: 'TT',
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN',
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR',
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1649',
    code: 'TC',
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV',
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG',
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA',
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
  },
  {
    name: 'United States',
    dial_code: '+1',
    code: 'US',
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY',
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ',
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dial_code: '+58',
    code: 'VE',
  },
  {
    name: 'Vietnam',
    dial_code: '+84',
    code: 'VN',
  },
  {
    name: 'Virgin Islands, British',
    dial_code: '+1284',
    code: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    dial_code: '+1340',
    code: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'WF',
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE',
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW',
  },
];

exports.backgroundVideos = [
  {
    id: 'bg-0',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/01+MijA+BG.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/01+MijA+BG.mp4',
  },
  {
    id: 'bg-1',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/03+BG+BG0.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/03+BG+BG0.mp4',
  },
  {
    id: 'bg-2',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/04+Colorful+lines+BG.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/04+Colorful+lines+BG.mp4',
  },
  {
    id: 'bg-3',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/05+Crystal+Tunnel+BG.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/05+Crystal+Tunnel+BG.mp4',
  },
  {
    id: 'bg-4',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/06+Gatsby+BG.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/06+Gatsby+BG.mp4',
  },
  {
    id: 'bg-5',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/07+Hexagon+Vj+Loops BG.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/07+Hexagon+Vj+Loops BG.mp4',
  },
  {
    id: 'bg-6',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/08+Pink+Fashion+BG.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/08+Pink+Fashion+BG.mp4',
  },
  {
    id: 'bg-7',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/09+Pixel+Art+BG.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/09+Pixel+Art+BG.mp4',
  },
  {
    id: 'bg-8',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/0BG+Pink+BG.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/0BG+Pink+BG.mp4',
  },
  {
    id: 'bg-9',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/10+Glitter+BG.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/10+Glitter+BG.mp4',
  },
  {
    id: 'bg-10',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/11+Vj+Lights+8bit+BG.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/11+Vj+Lights+8bit+BG.mp4',
  },
  {
    id: 'bg-11',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/1BG+Vj+Lights+8bit+BG.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/1BG+Vj+Lights+8bit+BG.mp4',
  },
  {
    id: 'bg-12',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Abstract_Menu_2.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Abstract_Menu_2.mp4',
  },
  {
    id: 'bg-15',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/BloomMenu_2.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/BloomMenu_2.mp4',
  },
  {
    id: 'bg-16',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Blue_Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Blue_Menu1.mp4',
  },
  {
    id: 'bg-17',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Cut_Out_Tap_to_start.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Cut_Out_Tap_to_start.mp4',
  },
  {
    id: 'bg-18',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Geo_Menu_ipad.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Geo_Menu_ipad.mp4',
  },
  {
    id: 'bg-19',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Glamour_Menu_2.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Glamour_Menu_2.mp4',
  },
  {
    id: 'bg-20',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Grey_Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Grey_Menu1.mp4',
  },
  {
    id: 'bg-21',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Mint_Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Mint_Menu1.mp4',
  },
  {
    id: 'bg-22',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Modern_Glow_Menu2.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Modern_Glow_Menu2.mp4',
  },
  {
    id: 'bg-23',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Orange_Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Orange_Menu1.mp4',
  },
  {
    id: 'bg-24',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/POP_Vertical_Menu_2_ipad.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/POP_Vertical_Menu_2_ipad.mp4',
  },
  {
    id: 'bg-25',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Purple_Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Purple_Menu1.mp4',
  },
  {
    id: 'bg-26',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Red_Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Red_Menu1.mp4',
  },
  {
    id: 'bg-27',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Start_Screen_flood_v10.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Start_Screen_flood_v10.mp4',
  },
  {
    id: 'bg-28',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Start_Screen_flood_v11.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Start_Screen_flood_v11.mp4',
  },
  {
    id: 'bg-29',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Start_Screen_flood_v12.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Start_Screen_flood_v12.mp4',
  },
  {
    id: 'bg-30',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Start_Screen_flood_v1c.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Start_Screen_flood_v1c.mp4',
  },
  {
    id: 'bg-31',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Start_Screen_flood_v2a.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Start_Screen_flood_v2a.mp4',
  },
  {
    id: 'bg-32',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Start_Screen_flood_v3a.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Start_Screen_flood_v3a.mp4',
  },
  {
    id: 'bg-33',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Start_Screen_flood_v4a.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Start_Screen_flood_v4a.mp4',
  },
  {
    id: 'bg-34',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Start_Screen_flood_v5.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Start_Screen_flood_v5.mp4',
  },
  {
    id: 'bg-35',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/Start_Screen_flood_v9.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/Start_Screen_flood_v9.mp4',
  },
  {
    id: 'bg-36',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/TABS_V_Menu_2.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/TABS_V_Menu_2.mp4',
  },
  {
    id: 'bg-37',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/material.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/material.mp4',
  },
  {
    id: 'bg-38',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/sherbert.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/sherbert.mp4',
  },
  {
    id: 'bg-39',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/backgrounds/videos/starry_night_Main_Menu_2.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/backgrounds/videos/starry_night_Main_Menu_2.mp4',
  },
];

exports.tapToStartVideos = [
  {
    id: 'tap-0',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/01+MijA.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/01+MijA.mp4',
  },
  {
    id: 'tap-1',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/02+Pink.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/02+Pink.mp4',
  },
  {
    id: 'tap-2',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/03+BG+19.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/03+BG+19.mp4',
  },
  {
    id: 'tap-3',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/04+Colorful+lines.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/04+Colorful+lines.mp4',
  },
  {
    id: 'tap-4',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/05+Crystal+Tunnel.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/05+Crystal+Tunnel.mp4',
  },
  {
    id: 'tap-5',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/06+Gatsby.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/06+Gatsby.mp4',
  },
  {
    id: 'tap-6',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/07+Hexagon+Vj+Loops.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/07+Hexagon+Vj+Loops.mp4',
  },
  {
    id: 'tap-7',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/08+Pink+Fashion.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/08+Pink+Fashion.mp4',
  },
  {
    id: 'tap-8',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/09+Pixel+Art.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/09+Pixel+Art.mp4',
  },
  {
    id: 'tap-9',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/10+Glitter.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/10+Glitter.mp4',
  },
  {
    id: 'tap-10',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/11+Vj+Lights+8bit.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/11+Vj+Lights+8bit.mp4',
  },
  {
    id: 'tap-11',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/12+Vj+Lights+8bit.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/12+Vj+Lights+8bit.mp4',
  },
  {
    id: 'tap-12',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Abstract+Menu+2_1.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Abstract+Menu+2_1.mp4',
  },
  {
    id: 'tap-13',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Additive+Menu.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Additive+Menu.mp4',
  },
  {
    id: 'tap-14',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Back+to+Basics+Menu+2.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Back+to+Basics+Menu+2.mp4',
  },
  {
    id: 'tap-15',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/BloomMenu+2_2.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/BloomMenu+2_2.mp4',
  },
  {
    id: 'tap-16',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Blue+Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Blue+Menu1.mp4',
  },
  {
    id: 'tap-17',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Cut+Out+Tap+to+start.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Cut+Out+Tap+to+start.mp4',
  },
  {
    id: 'tap-18',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Geo+Menu+ipad.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Geo+Menu+ipad.mp4',
  },
  {
    id: 'tap-19',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Glamour+Menu+2.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Glamour+Menu+2.mp4',
  },
  {
    id: 'tap-20',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Grey+Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Grey+Menu1.mp4',
  },
  {
    id: 'tap-21',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Mint+Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Mint+Menu1.mp4',
  },
  {
    id: 'tap-22',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Modern+Glow+Menu2.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Modern+Glow+Menu2.mp4',
  },
  {
    id: 'tap-23',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Orange+Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Orange+Menu1.mp4',
  },
  {
    id: 'tap-24',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/POP+Vertical+Menu+2+ipad.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/POP+Vertical+Menu+2+ipad.mp4',
  },
  {
    id: 'tap-25',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Purple+Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Purple+Menu1.mp4',
  },
  {
    id: 'tap-26',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Red+Menu1.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Red+Menu1.mp4',
  },
  {
    id: 'tap-27',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Start_Screen_v10.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Start_Screen_v10.mp4',
  },
  {
    id: 'tap-28',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Start_Screen_v11.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Start_Screen_v11.mp4',
  },
  {
    id: 'tap-29',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Start_Screen_v12.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Start_Screen_v12.mp4',
  },
  {
    id: 'tap-30',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Start_Screen_v1c.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Start_Screen_v1c.mp4',
  },
  {
    id: 'tap-31',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Start_Screen_v2a.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Start_Screen_v2a.mp4',
  },
  {
    id: 'tap-32',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Start_Screen_v3a.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Start_Screen_v3a.mp4',
  },
  {
    id: 'tap-33',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Start_Screen_v4a.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Start_Screen_v4a.mp4',
  },
  {
    id: 'tap-34',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Start_Screen_v5.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Start_Screen_v5.mp4',
  },
  {
    id: 'tap-35',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/Start_Screen_v9.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/Start_Screen_v9.mp4',
  },
  {
    id: 'tap-36',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/TABS+V+Menu+2.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/TABS+V+Menu+2.mp4',
  },
  {
    id: 'tap-37',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/material.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/material.mp4',
  },
  {
    id: 'tap-38',
    thumbnailUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/sherbert.m4v',
    originalUrl: 'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/sherbert.mp4',
  },
  {
    id: 'tap-39',
    thumbnailUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-thumbnails/tap-to-start/videos/starry+night++Main+Menu+2_1.m4v',
    originalUrl:
      'https://pbsco-static.s3-us-west-2.amazonaws.com/asset-library/tap-to-start/videos/starry+night++Main+Menu+2_1.mp4',
  },
];

exports.replaceNewline = (input) => {
  const newline = String.fromCharCode(13, 10);
  return input.replaceAll('\\n', newline);
};

exports.randomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

exports.getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

exports.isElementScrollable = (el = document.body) => {
  // Compare the height to see if the element has scrollable content
  const hasScrollableContent = el.scrollHeight > el.clientHeight;
  if (!hasScrollableContent) {
    return false;
  }

  // It's not enough because the element's `overflow-y` style can be set as
  // * `hidden`
  // * `hidden !important`
  // In those cases, the scrollbar isn't shown
  const overflowYStyle = window.getComputedStyle(el).overflowY;
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

  return hasScrollableContent && !isOverflowHidden;
};

import { GET_PROPOSAL_DATA, GET_PROPOSAL_DATA_SUCCESS, GET_PROPOSAL_DATA_FAILURE } from '../actions/proposal';

const defaultState = {
  loading: false,
  error: null,
  data: [],
};

export function proposalData(state = defaultState, action) {
  switch (action.type) {
    case GET_PROPOSAL_DATA:
      return { ...defaultState, loading: true };
    case GET_PROPOSAL_DATA_SUCCESS:
      return { ...defaultState, loading: false, data: action.data };
    case GET_PROPOSAL_DATA_FAILURE:
      return { ...defaultState, loading: false, error: action.error };
    default:
      return state;
  }
}

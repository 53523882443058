class MockBackend {
  constructor() {
    this.URL = process.env.API_URL;
    this.TIMERMILLIS = 500;

    this.IMAGES = [
      { id: 'one', src: '/images/samples/people.jpg' },
      { id: 'two', src: '/images/samples/two.png' },
      { id: 'three', src: '/images/samples/three.png' },
      { id: 'four', src: '/images/samples/four.png' },
      { id: 'five', src: '/images/samples/five.png' },
      { id: 'six', src: '/images/samples/six.png' },
      { id: 'seven', src: '/images/samples/seven.png' },
      { id: 'eight', src: '/images/samples/eight.png' },
      { id: 'nine', src: '/images/samples/nine.png' },
    ];

    this.BACKGROUND = '/images/samples/background2.jpg';
    this.LOGO = '/images/samples/logo.png';

    this.GET_EVENT = {
      eventId: 'ABW32',
      createdDate: '2018-01-03T18:00:00+00:00',
      serial: 'AHUQO11KNWWHA',
      boothName: 'Demo Booth',
      title: "Nickolodean's Throwback Party",
      eventDate: '2018-05-01T12:00:00+00:00',
      logoUrls: {
        large: this.LOGO,
      },
      backgroundUrls: {
        large: this.BACKGROUND,
      },
    };

    this.GET_MEDIAS = [];
    this.IMAGES.forEach((image) => {
      const media = {
        mediaId: image.id,
        type: 'image/jpeg',
        thumbnails: {
          large: image.src,
        },
        original: {
          mediaUrls: image.src,
        },
      };

      this.GET_MEDIAS.push(media);
    });

    this.ORGANIZATIONS = [
      {
        organizationId: '3566564',
        name: 'Brandon Wong',
        owner: 'Brandon Wong',
        email: 'brandon@pbsco.com',
        plans: [
          { planId: '345345', quantity: 1 },
          { planId: '23432', quantity: 3 },
          { planId: '353453', quantity: 5 },
        ],
        devices: [
          { deviceId: '123', name: "Brandon's Device" },
          { deviceId: '987', name: "David's Device" },
          { deviceId: '', name: 'Unassigned' },
        ],
      },
    ];

    this.BILLING = {
      organizationId: '3566564',
      paymentMethod: 'AMEX',
      cardNumber: '4444444444444448',
      expiryMonth: '01',
      expiryYear: '2020',
      securityCode: '567',
      zipCode: '95001',
    };

    this.INVOICES = {
      invoices: [
        {
          date: '2018-05-01T12:00:00+00:00',
          url: 'http:\\link.to.invoice',
          amount: '250',
          status: 'Paid',
        },
        {
          date: '2018-05-01T12:00:00+00:00',
          url: 'http:\\link.to.invoice',
          amount: '175',
          status: 'Paid',
        },
        {
          date: '2018-05-01T12:00:00+00:00',
          url: 'http:\\link.to.invoice',
          amount: '136',
          status: 'Paid',
        },
        {
          date: '2018-05-01T12:00:00+00:00',
          url: 'http:\\link.to.invoice',
          amount: '137',
          status: 'Paid',
        },
      ],
    };

    this.PLANS = [
      // {
      //     planId: "345345",
      //     title: "Trial",
      //     description: "Test the app as long as you want with the watermark applied",
      //     cost: "0",
      //     type: "monthly",
      //     quantity: 1,
      //     features: []
      // },
      {
        planId: 'plan_DIUukNG4SDBubA',
        title: 'Plus',
        description: 'Upgrade to remove the watermark, link a single booth and start using the booth at events',
        cost: '149',
        discount: 250,
        type: 'monthly',
        quantity: 1,
        features: [
          { name: 'No watermark', enabled: true },
          { name: 'Photos', enabled: true },
          { name: 'Printing', enabled: true },
          { name: 'GIF', enabled: true },
          { name: 'Video', enabled: true },
          { name: 'Boomerang', enabled: true },
          { name: 'E-mail', enabled: true },
          { name: 'SMS', enabled: true },
          { name: 'Filters', enabled: true },
          { name: 'Analytics', enabled: true },
          { name: 'Offline Queue', enabled: true },
          { name: 'Overlays', enabled: true },
          { name: 'LED Integration', enabled: true },
          { name: 'Dropbox', enabled: true },
          { name: 'Branded Screens', enabled: true },
          { name: 'Microsite', enabled: true },
          { name: 'Brandable Microsite', enabled: false },
          { name: 'Microsite Slideshow', enabled: false },
          { name: 'Advanced Analytics', enabled: false },
          { name: 'Item Tracker', enabled: false },
          { name: 'NSFW Tracker', enabled: false },
          { name: 'FTP', enabled: false },
          { name: 'Surveys', enabled: false },
          { name: 'Animated Overlays', enabled: false },
          { name: 'Disclaimer Text', enabled: false },
        ],
      },
      {
        planId: 'plan_DIUtzt2CTZbcjx',
        title: 'Pro',
        description:
          'Offer the most features and customizations at your events. Includes ability to link 3 booths to your account',
        cost: '150',
        type: 'monthly',
        quantity: 3,
        features: [
          { name: 'No watermark', enabled: true },
          { name: 'Photos', enabled: true },
          { name: 'Printing', enabled: true },
          { name: 'GIF', enabled: true },
          { name: 'Video', enabled: true },
          { name: 'Boomerang', enabled: true },
          { name: 'E-mail', enabled: true },
          { name: 'SMS', enabled: true },
          { name: 'Filters', enabled: true },
          { name: 'Analytics', enabled: true },
          { name: 'Offline Queue', enabled: true },
          { name: 'Overlays', enabled: true },
          { name: 'LED Integration', enabled: true },
          { name: 'Dropbox', enabled: true },
          { name: 'Branded Screens', enabled: true },
          { name: 'Microsite', enabled: true },
          { name: 'Brandable Microsite', enabled: true },
          { name: 'Microsite Slideshow', enabled: true },
          { name: 'Advanced Analytics', enabled: true },
          { name: 'Item Tracker', enabled: true },
          { name: 'NSFW Tracker', enabled: true },
          { name: 'FTP', enabled: true },
          { name: 'Surveys', enabled: true },
          { name: 'Animated Overlays', enabled: true },
          { name: 'Disclaimer Text', enabled: true },
        ],
      },
      {
        planId: 'plan_DIUukNG4SDBubA',
        title: 'Plus',
        description: 'Upgrade to remove the watermark and start using the booth at events',
        cost: '129',
        type: 'annual',
        quantity: 1,
        features: [
          { name: 'No watermark', enabled: true },
          { name: 'Photos', enabled: true },
          { name: 'Printing', enabled: true },
          { name: 'GIF', enabled: true },
          { name: 'Video', enabled: true },
          { name: 'Boomerang', enabled: true },
          { name: 'E-mail', enabled: true },
          { name: 'SMS', enabled: true },
          { name: 'Filters', enabled: true },
          { name: 'Analytics', enabled: true },
          { name: 'Offline Queue', enabled: true },
          { name: 'Overlays', enabled: true },
          { name: 'LED Integration', enabled: true },
          { name: 'Dropbox', enabled: true },
          { name: 'Branded Screens', enabled: true },
          { name: 'Microsite', enabled: true },
          { name: 'Brandable Microsite', enabled: false },
          { name: 'Microsite Slideshow', enabled: false },
          { name: 'Advanced Analytics', enabled: false },
          { name: 'Item Tracker', enabled: false },
          { name: 'NSFW Tracker', enabled: false },
          { name: 'FTP', enabled: false },
          { name: 'Surveys', enabled: false },
          { name: 'Animated Overlays', enabled: false },
          { name: 'Disclaimer Text', enabled: false },
        ],
      },
      {
        planId: 'plan_DIUtzt2CTZbcjx',
        title: 'Pro',
        description:
          'Offer the most features and customizations at your events, includes ability to link 3 booths to your account',
        cost: '130',
        type: 'annual',
        quantity: 3,
        features: [
          { name: 'No watermark', enabled: true },
          { name: 'Photos', enabled: true },
          { name: 'Printing', enabled: true },
          { name: 'GIF', enabled: true },
          { name: 'Video', enabled: true },
          { name: 'Boomerang', enabled: true },
          { name: 'E-mail', enabled: true },
          { name: 'SMS', enabled: true },
          { name: 'Filters', enabled: true },
          { name: 'Analytics', enabled: true },
          { name: 'Offline Queue', enabled: true },
          { name: 'Overlays', enabled: true },
          { name: 'LED Integration', enabled: true },
          { name: 'Dropbox', enabled: true },
          { name: 'Branded Screens', enabled: true },
          { name: 'Microsite', enabled: true },
          { name: 'Brandable Microsite', enabled: true },
          { name: 'Microsite Slideshow', enabled: true },
          { name: 'Advanced Analytics', enabled: true },
          { name: 'Item Tracker', enabled: true },
          { name: 'NSFW Tracker', enabled: true },
          { name: 'FTP', enabled: true },
          { name: 'Surveys', enabled: true },
          { name: 'Animated Overlays', enabled: true },
          { name: 'Disclaimer Text', enabled: true },
        ],
      },
    ];
  }

  getEvent(eventId) {
    return fetch(`${this.URL}/v1/events/${eventId}`);
    // return new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve({
    //             eventId,
    //             eventDate: this.GET_EVENT.eventDate,
    //             title: this.GET_EVENT.title,
    //             logoUrls: this.GET_EVENT.logoUrls,
    //             backgroundUrls: this.GET_EVENT.backgroundUrls,
    //         });
    //     }, this.TIMER_MILLIS);
    // });
  }

  getMedias(eventId, haveIds) {
    let url = `${this.URL}/v1/events/${eventId}/medias`;
    if (typeof haveIds !== 'undefined' && haveIds !== null) {
      url += `?haveIds=${haveIds}`;
    }
    return fetch(url);
    // for (let i = 0; i < this.GET_MEDIAS.length; i += 1) {
    //     this.GET_MEDIAS[i].eventId = eventId;
    // }

    // return new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve(this.GET_MEDIAS);
    //     }, this.TIMER_MILLIS);
    // });
  }

  getMedia(eventId, mediaId, callback) {
    return fetch(`${this.URL}/v1/events/${eventId}/medias/${mediaId}`);
    // setTimeout(() => {
    //     this.GET_MEDIAS.find((media) => {
    //         if (media.mediaId === mediaId) {
    //             return callback(null, mediaId);
    //         }

    //         return false;
    //     });
    // }, this.TIMER_MILLIS);
  }

  getPlans() {
    return this.PLANS;
  }

  getDevices(organizationId) {
    const devices = [];
    return this.ORGANIZATIONS[0].devices;
  }

  getInvoices(organizationId) {
    const invoices = [];
    return this.INVOICES.invoices;
  }

  getProfile(organizationId) {
    const profile = {};
    profile.id = this.ORGANIZATIONS[0].organizationId;
    profile.name = this.ORGANIZATIONS[0].owner;
    profile.email = this.ORGANIZATIONS[0].email;
    profile.devices = this.ORGANIZATIONS[0].devices;

    return profile;
  }

  // getBilling(organizationId){
  //     const billing = {};
  //     billing.id = this.BILLING.organizationId;
  //     billing.paymentMethod = this.BILLING.paymentMethod;
  //     billing.cardNumber = this.BILLING.cardNumber;
  //     billing.expiryMonth = this.BILLING.expiryMonth;
  //     billing.expiryYear = this.BILLING.expiryYear;
  //     billing.securityCode = this.BILLING.securityCode;
  //     billing.zipCode = this.BILLING.zipCode;

  //     return billing;
  // }

  removeDevice({ deviceId }) {
    const devices = [];

    this.ORGANIZATIONS[0].devices.forEach((device) => {
      if (device.deviceId !== deviceId) {
        devices.push(device);
      }
    });
    devices.push({ deviceId: '', name: 'Unassigned' });
    this.ORGANIZATIONS[0].devices = devices;
    return devices;
  }

  signUp({ user }) {
    if (user.email === 'moira@moira.com' && user.password === 'thisismypassword') {
      return true; // ?
    }
    return false;
  }

  logIn({ user }) {
    if (user.email === 'moira@moira.com' && user.password === 'thisismypassword') {
      return {
        user,
        token: 'rgieuhfojdjhjsdadsjhgreofiwpda',
      };
    }
    return null;
  }

  resetPasswordInit({ email }) {
    return { email, isInvalidEmail: false };
  }

  resetPasswordValidate({ token }) {
    let data;

    switch (token) {
      case 'INVALID':
        data = { token, isTokenInvalid: true, isTokenExpired: false };
        break;
      case 'EXPIRED':
        data = { token, isTokenInvalid: false, isTokenExpired: true };
        break;
      case 'VALID':
      default:
        data = { token, isTokenInvalid: false, isTokenExpired: false };
        break;
    }

    return {
      status: 200,
      json() {
        return {
          data: {
            resetPasswordValidate: data,
          },
        };
      },
    };
  }

  resetPasswordFinally({ token, newPassword }) {
    switch (newPassword) {
      case 'failure':
        return { status: 400 };
      default:
        return { status: 200 };
    }
  }

  updateEnvironment({ env }) {
    // placeholder
  }
}

export default MockBackend;

import ReactGA from 'react-ga4';

export const GA_TRACKING_ID = require('../constants').get().analyticsTrackingID;

export const initGA = () => {
  ReactGA.initialize(GA_TRACKING_ID, {
    debug: false,
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });
};

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

export const logEvent = (category = '', action = '', label = '') => {
  if (category && action) {
    ReactGA.event({ category, action, label });
  }
};

export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};

export const transformAnalyticsData = (data, transformValue) => {
  return Object.entries(data).map(([key, value]) => ({
    id: key,
    name: value.name,
    value: transformValue ? transformValue(value.value) : value.value,
  }));
};

export const aggregateAnalyticsData = (data, total) => {
  return transformAnalyticsData(data, (value) => Math.round((parseFloat(value) / 100) * total));
};

export const aggregateTotal = (data) => {
  return data.reduce((aggregate, item) => {
    return aggregate + item.value;
  }, 0);
};

import _ from 'lodash';
import React from 'react';
import App from 'next/app';
import Router, { withRouter } from 'next/router';
import Head from 'next/head';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import { ThemeProvider } from 'theme-ui';
import whatInput from 'what-input'; // Don't remove, adds classes to html element
import initializeStore from '../src/scripts/stores/store';
import constants from '../src/scripts/constants';
import ConsoleLoger from '../src/scripts/components/ConsoleLoger';
import { initGA, logPageView } from '../src/scripts/utils/analytics';
import { theme } from '../src/scripts/components/webapp/theme';

function appendHsScript() {
  const hsScript = document.createElement('script');
  hsScript.async = true;
  hsScript.defer = true;
  hsScript.src = '//js.hs-scripts.com/1555762.js';
  hsScript.id = 'hs-script-loader';
  document.head.appendChild(hsScript);
}

Router.onRouteChangeStart = () => {
  const hsScript = document.getElementById('hs-script-loader');
  if (hsScript) {
    hsScript.remove();
  }
};

Router.onRouteChangeComplete = () => {
  appendHsScript();
  if (window.Appcues) {
    window.Appcues.page();
  }
};

class MyApp extends App {
  static async getInitialProps({ Component, router, ctx }) {
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    return { pageProps, router };
  }

  componentDidMount() {
    initGA();
    logPageView();
    appendHsScript();
    Router.events.on('routeChangeComplete', logPageView);

    // const token = Utils.retrieveFromLocalStorage('token') || {};
    // token.accessToken = token.token;
    // const keys = ['accessToken', 'operatorId', 'organizationId', 'email', 'newUser', 'superUser'];
    // const operator = _.pickBy(_.pick(token, keys), val => !_.isUndefined(val));

    if (constants.get().consoleLoger) {
      setTimeout(() => {
        console.log(
          'Navigator:',
          _.pick(navigator, [
            'userAgent',
            'platform',
            'vendor',
            'product',
            'productSub',
            'maxTouchPoints',
            'hardwareConcurrency',
            'deviceMemory',
          ])
        );
        console.log('MediaRecorder:', !!window.MediaRecorder);
      }, 5000);
    }
  }

  render() {
    const { Component, pageProps, store, router } = this.props;
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Head>
            <meta name="viewport" content="width=device-width,initial-scale=1" />
          </Head>
          <Component {...pageProps} key={router.route} />
        </ThemeProvider>
      </Provider>
    );
  }
}

export default withRouter(withRedux(initializeStore)(withReduxSaga(MyApp)));

/**
 * Provide an order ID or email to bind the logged-in operator to a Shopify
 * account to verify they have purchased a photo booth
 */
export const GET_SHOPIFY_CUSTOMER_EMAIL = 'GET_SHOPIFY_CUSTOMER_EMAIL';
export function getShopifyCustomerEmail(queryStr, accessToken) {
  return {
    type: GET_SHOPIFY_CUSTOMER_EMAIL,
    queryStr,
    accessToken,
  };
}
export const GET_SHOPIFY_CUSTOMER_EMAIL_REQUEST = 'GET_SHOPIFY_CUSTOMER_EMAIL_REQUEST';
export function getShopifyCustomerEmailRequest() {
  return {
    type: GET_SHOPIFY_CUSTOMER_EMAIL_REQUEST,
  };
}
export const GET_SHOPIFY_CUSTOMER_EMAIL_SUCCESS = 'GET_SHOPIFY_CUSTOMER_EMAIL_SUCCESS';
export function getShopifyCustomerEmailSuccess(email) {
  return {
    type: GET_SHOPIFY_CUSTOMER_EMAIL_SUCCESS,
    email,
  };
}
export const GET_SHOPIFY_CUSTOMER_EMAIL_FAILURE = 'GET_SHOPIFY_CUSTOMER_EMAIL_FAILURE';
export function getShopifyCustomerEmailFailure(error) {
  return {
    type: GET_SHOPIFY_CUSTOMER_EMAIL_FAILURE,
    error,
  };
}

/**
 * Fetch the complete list of marketing materials
 */
export const GET_MARKETING_MATERIALS = 'GET_MARKETING_MATERIALS';
export function getMarketingMaterials(accessToken) {
  return {
    type: GET_MARKETING_MATERIALS,
    accessToken,
  };
}
export const GET_MARKETING_MATERIALS_REQUEST = 'GET_MARKETING_MATERIALS_REQUEST';
export function getMarketingMaterialsRequest() {
  return {
    type: GET_MARKETING_MATERIALS_REQUEST,
  };
}
export const GET_MARKETING_MATERIALS_SUCCESS = 'GET_MARKETING_MATERIALS_SUCCESS';
export function getMarketingMaterialsSuccess(data) {
  return {
    type: GET_MARKETING_MATERIALS_SUCCESS,
    data,
  };
}
export const GET_MARKETING_MATERIALS_FAILURE = 'GET_MARKETING_MATERIALS_FAILURE';
export function getMarketingMaterialsFailure(error) {
  return {
    type: GET_MARKETING_MATERIALS_FAILURE,
    error,
  };
}

/**
 * Fetch a URL for a marketing material item using its key
 */
export const DOWNLOAD_MARKETING_MATERIAL = 'DOWNLOAD_MARKETING_MATERIAL';
export function downloadMarketingMaterial(key, accessToken) {
  return {
    type: DOWNLOAD_MARKETING_MATERIAL,
    key,
    accessToken,
  };
}
export const DOWNLOAD_MARKETING_MATERIAL_REQUEST = 'DOWNLOAD_MARKETING_MATERIAL_REQUEST';
export function downloadMarketingMaterialRequest() {
  return {
    type: DOWNLOAD_MARKETING_MATERIAL_REQUEST,
  };
}
export const DOWNLOAD_MARKETING_MATERIAL_SUCCESS = 'DOWNLOAD_MARKETING_MATERIAL_SUCCESS';
export function downloadMarketingMaterialSuccess(data) {
  return {
    type: DOWNLOAD_MARKETING_MATERIAL_SUCCESS,
    data,
  };
}
export const DOWNLOAD_MARKETING_MATERIAL_FAILURE = 'DOWNLOAD_MARKETING_MATERIAL_FAILURE';
export function downloadMarketingMaterialFailure(error) {
  return {
    type: DOWNLOAD_MARKETING_MATERIAL_FAILURE,
    error,
  };
}

import {
  GET_SHOPIFY_CUSTOMER_EMAIL,
  GET_SHOPIFY_CUSTOMER_EMAIL_REQUEST,
  GET_SHOPIFY_CUSTOMER_EMAIL_SUCCESS,
  GET_SHOPIFY_CUSTOMER_EMAIL_FAILURE,
  GET_MARKETING_MATERIALS,
  GET_MARKETING_MATERIALS_REQUEST,
  GET_MARKETING_MATERIALS_SUCCESS,
  GET_MARKETING_MATERIALS_FAILURE,
} from '../actions/marketing';

const defaultState = {
  loading: false,
  error: null,
  data: [],
};

export function marketingMaterials(state = defaultState, action) {
  switch (action.type) {
    case GET_MARKETING_MATERIALS_REQUEST:
      return { ...defaultState, loading: true };
    case GET_MARKETING_MATERIALS_SUCCESS:
      return { ...defaultState, loading: false, data: action.data };
    case GET_MARKETING_MATERIALS_FAILURE:
      return { ...defaultState, loading: false, error: action.error };
    default:
      return state;
  }
}

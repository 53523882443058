const transition = 'all 100ms ease-in-out';

const buttonStyles = {
  fontFamily: 'body',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textAlign: 'center',
  paddingLeft: 4,
  paddingRight: 4,
  borderRadius: 100,
  minWidth: 260,
  cursor: 'pointer',
  '&[disabled]': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
};

const alertStyles = {
  marginBottom: 4,
  borderRadius: 1,
  fontSize: 1,
  fontWeight: 'body',
};

const cardStyles = {
  marginBottom: 4,
  padding: 5,
  borderRadius: 4,
  bg: 'white',
  transition,
  fontFamily: 'body',
};

export const theme = {
  space: [0, 6, 12, 16, 24, 36, 48, 64, 96, 128],
  breakpoints: ['768px', '1024px', '1440px'],
  radii: [0, 2, 12, 24, 28],
  colors: {
    text: '#222222',
    background: '#F4F4F4',
    primary: '#9775f7',
    secondary: '#E7E7E9',
    accent: '#9775f7',
    accentLight: '#E4DDFC',
    accentTint: '#F6F3FF',
    accentDark: '#7054C9',
    success: '#60BB60',
    successDark: '#3C643D',
    warning: '#f39c12',
    warningDark: '#DC5319',
    warningLight: '#F9E4DB',
    error: '#EA71AD',
    grayLight: '#f4f4f4',
    gray: '#b7b7b7',
    grayMedium: '#777777',
    grayDark: '#5d5d5d',
  },
  sizes: {
    container: 1024,
  },
  fonts: {
    body: 'sofia-pro, sans-serif',
    heading: 'inherit',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  text: {
    pageTitle: {
      marginBottom: 6,
    },
    muted: {
      color: 'rgba(0,0,0,0.5)',
    },
    tiny: {
      color: 'rgba(0,0,0,0.4)',
      fontSize: 0,
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    error: {
      color: 'error',
    },
    success: {
      color: 'success',
    },
    successDark: {
      color: 'successDark',
    },
    accent: {
      color: 'accent',
    },
    truncated: {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },

  layout: {
    small: {
      maxWidth: 630 + 48,
      px: [0, 2, 4],
    },
    medium: {
      maxWidth: 1077 + 48,
      px: [0, 2, 4],
    },
  },

  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  links: {
    primary: {
      color: 'primary',
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    inline: {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
  forms: {
    input: {
      backgroundColor: 'grayLight',
      boxShadow: '0 0 0 1px transparent !important',
      transition: 'box-shadow 100ms, border 100ms',
      border: 0,
      borderRadius: 1,
      '&:focus': {
        backgroundColor: 'white !important',
        outline: 0,
        boxShadow: '0 0 0 1px #9775f7 !important',
      },
      '&[type="file"]': {
        ...buttonStyles,
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      marginBottom: 4,
      fontSize: 5,
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      marginBottom: 4,
      fontSize: 4,
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      marginBottom: 2,
      fontSize: 3,
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2,
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
    hr: {
      color: 'grayLight',
      marginBottom: 5,
    },
    blockList: {
      paddingLeft: 0,
      listStyle: 'none',
    },
    section: {
      marginBottom: 6,
    },
    ul: {
      padding: 0,
    },
  },
  cards: {
    primary: {
      ...cardStyles,
    },
    secondary: {
      ...cardStyles,
      backgroundColor: 'grayLight',
    },
    page: {
      ...cardStyles,
      borderRadius: 24,
      px: [5, 8, 8, 8, 9],
      py: [5, 7],
    },
    large: {
      ...cardStyles,
      borderRadius: [0, 48],
      p: [3, 6],
    },
    accent: {
      ...cardStyles,
      borderRadius: 3,
      p: 4,
      backgroundColor: 'accentTint',
    },
    outline: {
      ...cardStyles,
      border: '2px solid',
      borderColor: 'grayLight',
    },
    outlineButton: {
      ...cardStyles,
      border: '2px solid',
      borderColor: 'grayLight',
      '&:focus': {
        borderColor: 'gray',
      },
    },
    outlineSelected: {
      ...cardStyles,
      border: '2px solid',
      borderColor: 'accent',
      bg: 'accentTint',
    },
  },
  alerts: {
    info: {
      ...alertStyles,
      color: 'accentDark',
      bg: 'accentTint',
    },
    success: {
      ...alertStyles,
      color: 'white',
      bg: 'success',
    },
    warning: {
      ...alertStyles,
      color: 'warningDark',
      bg: 'warningLight',
    },
    error: {
      ...alertStyles,
      color: 'white',
      bg: 'error',
    },
  },
  buttons: {
    primary: {
      ...buttonStyles,
      bg: 'primary',
    },
    secondary: {
      ...buttonStyles,
      bg: 'accentLight',
      color: 'accentDark',
    },
    success: {
      ...buttonStyles,
      bg: 'success',
    },
    error: {
      ...buttonStyles,
      bg: 'error',
    },
    text: {
      ...buttonStyles,
      bg: 'transparent',
      color: 'text',
      textTransform: 'none',
      padding: 0,
      minWidth: 0,
      textDecoration: 'underline',
    },
    textPrimary: {
      ...buttonStyles,
      bg: 'transparent',
      color: 'primary',
      textTransform: 'none',
      padding: 0,
      minWidth: 0,
    },
    listItem: {
      bg: 'transparent',
      color: 'text',
      p: 3,
      pr: 1,
      width: '100%',
    },
    breadcrumb: {
      fontFamily: 'body',
      display: 'inline-flex',
      bg: 'transparent',
      color: 'text',
      p: 0,
      mr: 1,
      '&:after': {
        content: '"/"',
        display: 'inline-flex',
        ml: 1,
      },
    },
    breadcrumbCurrent: {
      fontFamily: 'body',
      display: 'inline-flex',
      bg: 'transparent',
      color: 'grayMedium',
      p: 0,
      cursor: 'default',
    },
    bare: {
      p: 0,
      bg: 'transparent',
      borderRadius: 0,
    },
  },
  images: {
    avatar: {
      borderRadius: 99999,
      width: 48,
      height: 48,
      objectFit: 'cover',
    },
  },
  boxes: {
    placeholder: {
      bg: 'grayLight',
      borderRadius: 1,
    },
  },
};

/**
 * Fetch Event overlays without accessToken
 */
export const GET_PROPOSAL_OVERLAYS = 'GET_PROPOSAL_OVERLAYS';
export function getProposalOverlays(eventId) {
  return {
    type: GET_PROPOSAL_OVERLAYS,
    eventId,
  };
}
export const GET_PROPOSAL_OVERLAYS_SUCCESS = 'GET_PROPOSAL_OVERLAYS_SUCCESS';
export function getProposalOverlaysSuccess(data) {
  return {
    type: GET_PROPOSAL_OVERLAYS_SUCCESS,
    data,
  };
}
export const GET_PROPOSAL_OVERLAYS_FAILURE = 'GET_PROPOSAL_OVERLAYS_FAILURE';
export function getProposalOverlaysFailure(error) {
  return {
    type: GET_PROPOSAL_OVERLAYS_FAILURE,
    error,
  };
}

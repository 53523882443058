import { LIST_COUNTRIES, LIST_COUNTRIES_SUCCESS, LIST_COUNTRIES_FAILURE } from '../actions/countries';

const defaultState = {
  loading: false,
  error: null,
  data: [],
};

export function countries(state = defaultState, action) {
  switch (action.type) {
    case LIST_COUNTRIES:
      return { ...defaultState, loading: true };
    case LIST_COUNTRIES_SUCCESS:
      return { ...defaultState, loading: false, data: action.data };
    case LIST_COUNTRIES_FAILURE:
      return { ...defaultState, loading: false, error: action.error };
    default:
      return state;
  }
}

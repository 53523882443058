import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import createReduxWaitForMiddleware from 'redux-wait-for-action';

import rootReducer from '../reducers/reducers';
import Saga from '../sagas/sagas';
import QuesoBackend from '../backend/backendQueso';
import SalsaBackend from '../backend/backendSalsa';
import FiestaBackend from '../backend/backendFiesta';
import BillingBackend from '../backend/backendBilling';
import SuccessBackend from '../backend/backendSuccess';
import MockBackend from '../backend/mock';
import Backend from '../backend/backend';

const quesoBackend = new QuesoBackend();
const salsaBackend = new SalsaBackend();
const fiestaBackend = new FiestaBackend();
const billingBackend = new BillingBackend();
const mockBackend = new MockBackend();
const successBackend = new SuccessBackend();

const apis = {
  queso: quesoBackend,
  salsa: salsaBackend,
  fiesta: fiestaBackend,
  mock: mockBackend,
  billing: billingBackend,
  success: successBackend,
};
const backend = new Backend(apis);
const saga = new Saga(backend);

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function initializeStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware([sagaMiddleware, createReduxWaitForMiddleware()])
  );

  store.sagaTask = sagaMiddleware.run(saga.rootSaga);

  return store;
}

export default initializeStore;

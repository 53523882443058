import moment from 'moment';
import _ from 'lodash';
import 'isomorphic-unfetch';
import { getContrastColor } from '../utils/utils';

const { quesoURL } = require('../constants').get();

class BackendQueso {
  constructor() {
    this.URL = quesoURL;
  }

  getEvent({ eventId, token, subdomain }) {
    return fetch(`${this.URL}/v1/events/${eventId}`);
  }

  getMediaIndex({ eventId, subdomain, filters, dateAfter }) {
    return this.getMedias({ eventId, subdomain, filters, index: true });
  }

  getMediasWithFilters({ eventId, eventMedias, subdomain, filters }) {
    return this.getMedias({ eventId, eventMedias, subdomain, filters });
  }

  getMedias({ eventId, eventMedias, subdomain, filters, index }) {
    const haveIds = _.map(eventMedias, 'mediaId');
    let url = `${this.URL}/v1/events/${eventId}/medias`;
    let queryStringStarted = false;
    if (typeof haveIds !== 'undefined' && haveIds !== null) {
      url += `?haveIds=${haveIds}`;
      queryStringStarted = true;
    }
    if (typeof filters !== 'undefined' && filters !== null) {
      if (queryStringStarted) {
        url += '&';
      } else {
        url += '?';
      }
      url += filters;
    }
    if (index) {
      if (queryStringStarted) {
        url += '&';
      } else {
        url += '?';
      }
      url += 'index=true&limit=0';
    } else {
      if (queryStringStarted) {
        url += '&';
      } else {
        url += '?';
      }
      url += 'limit=12';
    }
    return fetch(url);
  }

  getMedia({ eventId, mediaId, subdomain }) {
    return fetch(`${this.URL}/v1/events/${eventId}/medias/${mediaId}`);
  }

  getTemplate({ eventId, mediaId }) {
    return fetch(`${this.URL}/v1/events/${eventId}/templates/${mediaId}`);
  }

  sharePhoto({ eventId, mediaId, email }) {
    const data = { emailTo: email };
    return fetch(`${this.URL}/v1/events/${eventId}/medias/${mediaId}/share`, {
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  // TRANSFORMATIONS //

  transformEvent({ eventBody }) {
    if (eventBody && eventBody.status !== 'ERROR') {
      const { eventId } = eventBody;
      const event = {
        ...eventBody,
        date: moment(eventBody.dateCreated).format('MMMM Do, YYYY'),
        dateMoment: moment(eventBody.dateCreated),
        logo: eventBody.logoUrls.large,
        eventMedias: [],
        hasMore: true,
        contactOwner: eventBody.publicOwnerEmail,
        passwordProtected: false,
      };

      if (typeof eventBody.backgroundUrls.large !== 'undefined' && eventBody.backgroundUrls.large !== null) {
        event.background = eventBody.backgroundUrls.large;
        event.backgroundColor = '#ffffff';
      } else {
        event.backgroundColor = eventBody.backgroundColor;
        event.background = 'none';
      }

      event.accentColor = event.micrositeAccentColor || '#000000';
      event.contrastColor = event.micrositeAccentColor ? getContrastColor(event.micrositeAccentColor) : '#ffffff';
      event.liveGallery = {
        theme: 'light',
        leadSection: true,
        socialMediaSection: true,
      };
      event.organization = {
        plan: 'Plus',
        licenseCount: 1,
      };

      return event;
    }
    return null;
  }

  transformMedia({ mediaBody }) {
    if (mediaBody && mediaBody.status !== 'ERROR') {
      return this.transformMedias({ mediasBody: [mediaBody] });
    }
    return null;
  }

  transformMedias({ mediasBody }) {
    if (mediasBody && mediasBody.status !== 'ERROR') {
      const medias = {};
      mediasBody.forEach((media) => {
        medias[media.mediaId] = {};
        medias[media.mediaId].src = media.thumbnailUrls.large;
        medias[media.mediaId].static = media.thumbnailUrls.large;
        medias[media.mediaId].original = media.mediaUrls.original;
        medias[media.mediaId].id = media.mediaId;
        if (media.type === 'video/mp4') {
          medias[media.mediaId].type = 'video';
        } else {
          medias[media.mediaId].type = 'image';
        }
        if (media.isTemplate) {
          medias[media.mediaId].isTemplate = true;
        } else {
          medias[media.mediaId].isTemplate = false;
        }
      });
      return medias;
    }
    return null;
  }

  updateEnvironment({ env }) {
    let url;
    if (env) {
      url = require('../constants').get(env).quesoURL;
    } else {
      url = require('../constants').get().quesoURL;
    }
    this.URL = url;
  }
}

export default BackendQueso;
